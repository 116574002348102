/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Breadcrumb from 'components/shared/Breadcrumb'
import ExperienceContent from 'components/shared/ExperienceContent'
import Quotes from 'components/shared/Quotes'

// Third Party
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  z-index: 1;
`

const ImgContainer = styled.div`
  position: absolute;
  height: 860px;
  bottom: 0;
  width: 100%;
`

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...ExperienceFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, acf, yoast_meta: yoast },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero left content={acf.banner} className="mb-lg-3" />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="px-sm-0 px-4">
        <ExperienceContent content={acf.content} />
      </div>
     

      <Container className="mb-4">
        <ImgContainer>
          <StyledImg loading="eager" fadeIn={false} alt="" fluid={acf.content.image.localFile.childImageSharp.fluid} />
        </ImgContainer>
        <Quotes noImage content={acf.content} />
      </Container>
     

    </Layout>
  )
}

export default PageTemplate
