import React from 'react'

// Video
import experienceVideo from 'img/experience_excellence.mp4'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const StyledExperienceContent = styled.div`
  background: ${props => props.theme.color.face.light};
  border-radius: 20px;
  padding: 80px 100px 80px 100px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 991px) {
    padding: 40px 50px 40px 50px;
  }

  @media screen and (max-width: 576px) {
    padding: 20px 30px 20px 30px;
  }
`

const InnerContent = styled.div`
  position: relative;
  padding: 100px 150px 100px 150px;

  p {
    line-height: 35px;

    @media screen and (max-width: 991px) {
      line-height: 22px;
    }
  }

  p:first-of-type {
    padding-top: 40px;

    @media screen and (max-width: 991px) {
      padding-top: 0;
    }
  }

  p + p {
    padding-top: 20px;

    @media screen and (max-width: 991px) {
      padding-top: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 40px 50px 40px 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  &::before {
    content: "Experience";
    position: absolute;
    top: 260px;
    left: -180px;
    font-size: ${props => props.theme.font.size.xxxl};
    font-weight: ${props => props.theme.font.weight.xl};
    color: rgba(0,0,0,0.2);
    transform: rotate(270deg);

    @media screen and (max-width: 769px) {
      content: none;
    }
  }

  &::after {
    content: "Excellence";
    position: absolute;
    bottom: 260px;
    right: -155px;
    font-size: ${props => props.theme.font.size.xxxl};
    font-weight: ${props => props.theme.font.weight.xl};
    color: rgba(0,0,0,0.2);
    transform: rotate(270deg);

    @media screen and (max-width: 769px) {
      content: none;
    }
  }
`

const Corner = styled.div`
  position: absolute;

  ${props => props.position !== 'center' && css`
    width: 77px;
    height: 77px;
  `}

  ${props => props.position && {
    'topLeft': css`
      border-left: 2px solid rgba(0, 0, 0, 0.2);
      border-top: 2px solid rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0;
    `,
    'bottomLeft': css`
      border-left: 2px solid rgba(0, 0, 0, 0.2);
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      left: 0;
      bottom: 0;
    `,
    'topRight': css`
      border-right: 2px solid rgba(0, 0, 0, 0.2);
      border-top: 2px solid rgba(0, 0, 0, 0.2);
      right: 0;
      top: 0;
    `,
    'bottomRight': css`
      border-right: 2px solid rgba(0, 0, 0, 0.2);
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      right: 0;
      bottom: 0;
    `
  }[props.position]}
`

const Plus = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 40px;
  height: 40px;
  position: relative;

  @media screen and (max-width: 1199px) {
    top: 185px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 2px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
  }
    
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 40px;
    height: 2px;
    background: rgba(0, 0, 0, 0.2);
  }
`

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 25px;
  margin-bottom: 40px;

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
`

const ExperienceContent = ({ content, className }) => {
  return (
    <StyledExperienceContent className={`container ${className ? `${className}` : ``}`}>
      <InnerContent>
        <Corner className="d-md-block d-none" position="topLeft" />
        <Corner className="d-md-block d-none" position="bottomLeft" />
        <Corner className="d-md-block d-none" position="topRight" />
        <Corner className="d-md-block d-none" position="bottomRight" />
        <Corner className="d-lg-block d-none" position="center" />

        <StyledVideo controls>
          <source src={experienceVideo} type="video/mp4" />
        </StyledVideo>

        <Plus />

        {parse(content.text)}

      </InnerContent>
      
    </StyledExperienceContent>
  )
}

export default ExperienceContent